import { cn } from '../../utils/cn';

const ListViewIcon = ({ className }: { className?: string }): JSX.Element => {
  return (
    <svg
      viewBox='0 0 12 12'
      xmlns='http://www.w3.org/2000/svg'
      className={cn('w-3 h-3 fill-current', className)}
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M2.14279 1.71429C1.6694 1.71429 1.28564 2.09805 1.28564 2.57144C1.28564 3.04482 1.6694 3.42858 2.14279 3.42858C2.61617 3.42858 2.99993 3.04482 2.99993 2.57144C2.99993 2.09805 2.61617 1.71429 2.14279 1.71429ZM5.14279 1.71429C4.6694 1.71429 4.28564 2.09805 4.28564 2.57144C4.28564 3.04482 4.6694 3.42858 5.14279 3.42858H9.85707C10.3305 3.42858 10.7142 3.04482 10.7142 2.57144C10.7142 2.09805 10.3305 1.71429 9.85707 1.71429H5.14279ZM5.14279 5.14287C4.6694 5.14287 4.28564 5.52662 4.28564 6.00001C4.28564 6.4734 4.6694 6.85715 5.14279 6.85715H9.85707C10.3305 6.85715 10.7142 6.4734 10.7142 6.00001C10.7142 5.52662 10.3305 5.14287 9.85707 5.14287H5.14279ZM4.28564 9.42858C4.28564 8.95519 4.6694 8.57144 5.14279 8.57144H9.85707C10.3305 8.57144 10.7142 8.95519 10.7142 9.42858C10.7142 9.90197 10.3305 10.2857 9.85707 10.2857H5.14279C4.6694 10.2857 4.28564 9.90197 4.28564 9.42858ZM1.28564 6.00001C1.28564 5.52662 1.6694 5.14287 2.14279 5.14287C2.61617 5.14287 2.99993 5.52662 2.99993 6.00001C2.99993 6.4734 2.61617 6.85715 2.14279 6.85715C1.6694 6.85715 1.28564 6.4734 1.28564 6.00001ZM2.14279 8.57144C1.6694 8.57144 1.28564 8.95519 1.28564 9.42858C1.28564 9.90197 1.6694 10.2857 2.14279 10.2857C2.61617 10.2857 2.99993 9.90197 2.99993 9.42858C2.99993 8.95519 2.61617 8.57144 2.14279 8.57144Z'
      />
    </svg>
  );
};

export { ListViewIcon };
